import React from 'react'

const AboutSection = () => {
    return (
        <section id='about' className='container p-6 md:p-8 mb-8 md:h-screen flex items-center'>
            <div className='text-white text-lg md:text-2xl font-medium flex flex-col gap-20'>
                <p>
                    Squaring Circles è una software house localizzata a Civitanova Marche, il nostro obiettivo è innovare il mondo business attraverso la fornitura di software, creando costantemente nuove soluzioni ai problemi che si presentano durante la crescita delle varie realtà.
                </p>
                <p>
                    Il nostro team progetta e sviluppa soluzioni in forma digitale, sia personalizzati per i clienti, sia fornendo le nostre soluzioni interne, che mirano a migliorare la quotidianità dei nostri utenti andando a ottimizzare operazioni quotidiane e servizi.
                </p>
            </div>
        </section>
    )
}

export default AboutSection