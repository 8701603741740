import React, { useEffect, useState } from 'react'
import "../App.css"


const HeroSection = () => {

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <>
            <div className='w-full h-full'>
                {
                    isMobile
                        ? <video className='absolute top-0 w-full h-full -z-20 object-cover' autoPlay loop muted playsInline src='./video/mobile-hero.mp4' />
                        : <video className='absolute top-0 w-full h-full -z-20 object-cover' autoPlay loop muted playsInline src='./video/desktophard_web.mp4' />
                }

                <div className='absolute top-0 w-full h-full -z-10 video-gradient' />
            </div>
            <div className='container p-6 md:p-8 h-screen flex items-center mt-32'>
                <div className='mobile-hero-row grid grid-cols-2 gap-8 w-full md:h-screen justify-center'>
                    <div className='flex flex-col justify-center '>
                        <div className='text-6xl lg:text-9xl text-white font-semibold mb-4'>Where Creativity<br />Meets Technology</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeroSection