import React from 'react'

const Navbar = () => {
    return (
        <nav className="p-8 pl-6 pr-4  w-full flex container justify-between absolute top-0">
            <div className="container-fluid w-full flex flex-wrap items-center justify-between mx-auto">
                <a href="#" className="flex items-center">
                    <img src="./logo.svg" className="h-6 mr-3 sm:h-9" alt="Squaring Circles Logo" />
                </a>
                <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                    <ul className="flex flex-row gap-8 font-medium text-xl">
                        <li>
                            <a href="#about" className="text-white">Chi Siamo</a>
                        </li>
                        <li>
                            <a href="#services" className="text-white">Servizi</a>
                        </li>
                        <li>
                            <a href="#contact" className="text-white">Contatti</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar