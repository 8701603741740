import React from 'react'

const ContactSection = () => {
    return (
        <section id='contact' className='container p-6 md:p-8 mb-16 mt-4 md:mb-32 md:mt-32'>
            <p className='text-3xl md:text-5xl text-left md:text-center text-white mb-8 font-medium'>CONTATTI</p>

            <div className='grid grid-cols-1 '>
                <div className='flex border border-white'>
                    <a className=" text-md md:text-2xl p-4 text-center w-full text-white" href="mailto:info@thesquaringcircles.com">
                        INFO@THESQUARINGCIRCLES.COM
                    </a>
                </div>
            </div>
        </section>
    )
}

export default ContactSection