import React from 'react'
import "../App.css"

function ServiceCard({ title, description, image, number }) {
    return (
        <div className='flex flex-col text-white relative border border-slate-200 p-4 md:p-8 service-container rounded-xl'>
            {/* <img src={image} className='backdrop-brightness absolute top-0 right-0 w-full h-full object-cover -z-10 ' /> */}
            <div className='flex flex-col justify-between h-full'>
                <p className='text-3xl md:text-5xl font-bold mb-6'>{title}</p>
                <p className='text-lg leading-5 md:text-xl md:leading-6 font-medium mb-8 md:mb-16 opacity-0 service-text'>
                    {description}
                </p>
                <div className='flex flex-row justify-start items-center align-middle w-full'>
                    <p className='font-bold text-3xl md:text-5xl mr-4'>{'0' + number}</p>
                    <div className='service-divider'></div>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard