import React from 'react'
import AboutSection from '../Components/AboutSection'
import ContactSection from '../Components/ContactSection'
import HeroSection from '../Components/HeroSection'
import Navbar from '../Components/Navbar'
import ServicesSection from '../Components/ServicesSection'
import FooterSection from '../Components/FooterSection'
import '../App.css'

const Home = () => {
    return (
        <div className='flex flex-col justify-center items-center'>
            <Navbar />
            <HeroSection />
            <AboutSection />
            <ServicesSection />
            <ContactSection />
            <FooterSection />
        </div>
    )
}

export default Home