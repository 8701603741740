import React from 'react'

const FooterSection = () => {
    return (
        <div className='bg-white w-full flex justify-center'>
            <div className='container grid grid-cols-1 md:grid-cols-3 gap-10 md:flex-row justify-between p-6 mt-10 mb-10'>
                <div className='flex flex-col'>
                    <div className='text-2xl font-semibold mb-2'>Squaring Circles S.r.l.</div>
                    <div className='text-sm font-medium'>Via Martiri di Belfiore 109</div>
                    <div className='text-sm font-medium mb-2'>62012, Civitanova Marche(MC)</div>
                    <div className='text-sm font-medium'>P.IVA e C.F. 02099890432</div>
                </div>

                <div className='flex flex-col'>
                    <div className='text-2xl font-semibold mb-2'>Link Utili</div>
                    <a className='text-sm font-medium' href="https://www.iubenda.com/privacy-policy/25320780" class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe" title="Privacy Policy ">Privacy Policy</a>
                </div>

                <div className='flex flex-col lg:ml-8 -order-1 md:order-3 lg:items-start'>
                    <img src='./images/logo_nero.svg' className='w-full h-full lg:p-8' />
                </div>

            </div>
        </div>
    )
}

export default FooterSection