import React from "react";
import ServiceCard from "./ServiceCard";

const ServicesSection = () => {
    return (
        <section id="services" className="container p-6 mt-10 md:mt-0 md:p-8 mb-10">
            <p className="text-4xl font-semibold text-white mb-10">SERVIZI</p>

            <div className="grid grid-cols-2 gap-10 mb-10 grid-services-mobile">
                <ServiceCard
                    title={"Sviluppo Software"}
                    number={"1"}
                    description={
                        "Offriamo un servizio di sviluppo dedicato di software generici e purpose per soddisfare esigenze specifiche del cliente e fornire un'interfaccia a strumentazioni integrate. Il nostro team si focalizza nell' analizzare le funzionalità richieste dal cliente e costruire un software intuitivo che risolva le problematiche esposte."
                    }
                    image={"./images/services/software_development.png"}
                />
                <ServiceCard
                    title={"Applicazioni Web"}
                    number={"2"}
                    description={
                        "Siamo specializzati nella creazione di applicazioni Web e mobile che forniscono esperienze utente intuitive e interfacce accattivanti. Il nostro team utilizza tecnologie all'avanguardia per creare applicazioni scalabili, affidabili e sicure che soddisfino i bisogni dei nostri clienti ed utenti."
                    }
                    image={"./images/services/webapp_development.png"}
                />
                <ServiceCard
                    title={"Applicazioni Mobile"}
                    number={"3"}
                    description={
                        "Oltre alle piattaforme Web siamo specializzati nella creazione di applicazioni Mobile per utenti e business che permettano la fruizione di servizi per migliorare la quotidianità dei consumer, grazie a soluzioni innovative sviluppate con un'interfaccia intuitiva e accattivante. Il nostro team utilizza tecnologie all'avanguardia per creare applicazioni scalabili, affidabili e sicure, sia native o cross-platform."
                    }
                    image={"./images/services/consulenza.png"}
                />
                <ServiceCard
                    title={"Integrazione Software"}
                    number={"4"}
                    description={
                        "Forniamo servizi di integrazione software per aiutare le aziende a ottimizzare i processi aziendali e migliorare il flusso produttivo. Il nostro team lavora per integrare diversi sistemi software e garantire che funzionino perfettamente insieme, fornendo ai clienti una soluzione più efficiente ed efficace."
                    }
                    image={"./images/services/software_integration.png"}
                />
                <ServiceCard
                    title={"Realtà Virtuale"}
                    number={"5"}
                    description={
                        "Progettazioni e sviluppo di realtà virtuale e aumentata per creare esperienze coinvolgenti in una gran varietà di settori. Ambienti virtuali interattivi, realistici e coinvolgenti che offrono un'esperienza unica e indimenticabile. In ogni nostro sviluppo vengono coinvolti artisti e creativi per formare un team dinamico per valorizzare il Bello di ogni prodotto."
                    }
                    image={"./images/services/vr.png"}
                />
                <ServiceCard
                    title={"Consulenza"}
                    number={"6"}
                    description={
                        "Offriamo servizi di consulenza software per aiutare i clienti a identificare le migliori soluzioni tecnologiche per le loro esigenze aziendali. L'obiettivo della nostra consulenza è comprendere appieno le esigenze, tramite studi dei processi e della realtà per fornire consulenza esperta sullo sviluppo, l'integrazione e l'implementazione del software."
                    }
                    image={"./images/services/consulenza.png"}
                />
            </div>
        </section>
    );
};

export default ServicesSection;
